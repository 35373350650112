<template>
    <div class="relative w-full mx-auto my-3 max-w-5xl">
        <p class="text-2xl text-center">Create Your Review</p>
        <div class="border border-gray-300 p-4 py-6 mt-8 relative">
            
            <form @submit.prevent="submitForm" method="post">
                <div class="grid md:grid-cols-1 lg:grid-cols-2 gap-4 py-4 w-full">
                    <div class="rounded-sm flex flex-col justify-between relative">
                        <label class="text-lg">Select Your Program:</label>
                        <select v-model="form.programId" class="h-8">
                            <option disabled value="">Please select one</option>
                            <option v-for="program in programs" :key="program.id" :value="program.id">
                                {{ program.name }}
                            </option>
                        </select>
                    </div>
                    <div class="rounded-sm flex flex-col justify-between relative">
                        <label class="text-lg">Select Your Institute:</label>
                        <select v-model="form.institute" class="h-8">
                            <option disabled value="">Please select one</option>
                            <option v-for="institute in institutes" :key="institute.id" :value="institute.id">
                                {{ institute.name }}
                            </option>
                        </select>
                    </div>
                    <div class="rounded-sm flex flex-col justify-between relative">
                        <label class="text-lg">Unit:</label>
                        <input-text v-model="form.unit" label="Unit" type="text" placeholder="Enter Your Unit" size="sm" class="w-full"/>
                    </div>
                    <div class="rounded-sm flex flex-col justify-between relative">
                        <label class="text-lg">Position:</label>
                    <input-text v-model="form.position" label="Position" type="number" placeholder="Enter Your Position" size="sm" class="w-full"/>
                    </div>
                </div>

                <div class="mb-2">
                    <label class="text-lg">Review:</label>
                    <textarea v-model="form.review" label="Review" type="text" placeholder="Write Your Review ..." class="w-full h-52 p-2"></textarea>
                </div>

                <div class="mb-3 text-center w-full">
                    <btn variant="info">Save</btn>
                </div>
            </form>
        </div>
  </div>
</template>

<script>
import InputText from "../../../components/input/input-text";
import Btn from "../../../components/btn";
import { mapGetters } from "vuex";

export default {
    name: 'create-review',
    components: {
        InputText,
        Btn,
    },
    data: {
        form: {
            programId: '',
            institute: '',
            unit: '',
            position: '',
            review: '',
        },
        loading: false,
        formErrors: [],
    },
    computed: {
        ...mapGetters({
            programs: 'reviews/programs',
            institutes: 'reviews/institutes',
        }),
    },
    methods: {
        submitForm() {

          this.loading = true;
          this.formErrors = [];

          //console.log( this.form );

          this.$store.dispatch('reviews/submitReview', {
              data: this.form,
              has_errors: ( errors ) => this.formErrors = errors,
              success: (response) => {
                  const data = response.data;
                  this.$router.push( '/' );
              }
          }).finally( () => this.loading = false );

        }

    },
    created() {
        this.$store.dispatch('reviews/callReviewsCreateApi');
    },
}

</script>
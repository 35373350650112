<template>
  <div>
    <div class="flex gap-2 items-center rounded inline-block" :class="disabledClass" >
      <slot name="prepend"></slot>
      <input ref="input" v-model="inputValue" v-bind="{...$attrs, ...{ type: getInputType, class: inputClass + ' w-full bg-transparent outline-none rounded'  }}">
      <slot name="append">
        <password-eye v-if="passwordEye" v-model:eye="password_eye"/>
      </slot>
    </div>

    <div class="mt-1 text-red-600" v-if="errors && errors.length">
      <div v-for="err in errors" class="leading-none mb-1">
        <slot name="error" :error="err">{{err}}</slot>
      </div>
    </div>

    <div class="mt-1" v-if="details || $slots.details">
      <slot name="details" :details="details">{{ details }}</slot>
    </div>

  </div>
</template>

<script>
import Spinner from "../spinner";
import {getColor} from "../../core/color";
import PasswordEye from "./password-eye";
export default {
  name: "input-text",
  components: {PasswordEye, Spinner},
  props: {
    inputClass: { type: String, default:'' },
    modelValue: { type: String, default:'' },
    href: { type:String  },
    variant: { type: String, default: 'primary' },
    size: { type: String, default: "base" },
    label: { type: String, default: null },
    type: { type: String, default: "text" },
    passwordEye: { type: Boolean, default: false },
    focus: { type: Boolean, default: false },
    errors: { type: Array, default: []},
    details: { type: String, default: null},
  },
  data() {
    return {
      password_eye: 'off',
      inputValue: ''
    }
  },
  created() { },
  mounted() {
    this.focus ? this.$refs.input.focus(): null;
  },
  computed: {
    getInput(){
      return this.$refs.input;
    },
    getInputType(){

      if(  this.type === 'password' && this.passwordEye) {
        return this.password_eye === 'on' ? 'text':'password';
      }
      return this.type;
    },
    getSize(){
      // return 'text-base px-2 py-2  ';
      switch ( this.size ) {
        case "xs":
          return 'text-xs px-3 py-1 ';
        case "sm":
          return 'text-base px-3 py-1 ';
        case "md":
          return 'text-base px-3 py-2 ';
        case "lg":
          return 'text-base px-3 py-3 ';
        case "xl":
          return 'text-lg px-3 py-3 ';
        default:
          return 'text-base px-3 py-2'
      }
    },
    getColor(){
      return  getColor(this.variant, {type: 'input'});
    },
    disabledClass(){
      return this.getSize + ( this.disabled ? ' bg-gray-200 text-white pointer-events-none ': ' '+this.getColor);
    }
  },
  // emits: ['update:modelValue'],
  watch: {
    modelValue( e ){
      console.log({e});
      this.inputValue = e;
    },
    inputValue( e ){
      this.$emit( 'update:modelValue', e );
    },
    focus(focus){
      focus ? this.$refs.input.focus(): null;
    }
  }
}
</script>

<style scoped>

</style>